::placeholder {
  color: #828282;
  opacity: .7;
}

:-ms-input-placeholder {
  color: #828282;
}

::-moz-placeholder {
  color: #828282;
}

::-webkit-scrollbar {
  width: 10px;
  background: #f3f3f7;
}

::-webkit-scrollbar-thumb {
  background: #09853d;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #007e37;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #09853d;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #007e37;
}

.btn, .form-control {
  border-radius: 0;
}

.form-group {
  margin-bottom: .5rem;
}

.btn-success {
  background-color: #007e37;
  border: 1px solid #007e37;
}

body a {
  color: #007e37;
}

a:hover {
  text-decoration: none;
}

input:disabled, textarea:disabled {
  cursor: not-allowed;
  background-color: #e8ebef !important;
}

/*# sourceMappingURL=index.2e5b1fac.css.map */
