/* ================ PLACEHOLDER ================ */
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #828282;
    opacity: 0.7; /* Firefox */
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #828282;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #828282;
}

/* ================ SCROLLBAR ================ */
::-webkit-scrollbar {
    width: 10px;
    background: #f3f3f7;
}
::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    /* -webkit-border-radius: 0px;
    border-radius: 0px; */
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #09853d;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}
::-webkit-scrollbar-thumb:hover {
    background: #007e37;
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #09853d;
}

/* ================ SELECTION ================ */
/* Seleção de texto colorida */
::selection {
    background-color: #4f6df5;
    background: #007e37;
    text-shadow: none;
    color: #fff;
}

.btn,
.form-control {
    border-radius: 0px;
}
.form-group {
    margin-bottom: 0.5rem;
}
.btn-success {
    background-color: #007e37;
    border: 1px solid #007e37;
}
/* 
.btn-primary:hover {
	background: white;
	color: #09853d;
	border: 2px solid #007e37;
	-webkit-transform: translate3d(0, -3px, 0);
	transform: translate3d(0, -3px, 0);
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
} */
body a {
    color: #007e37;
}
a:hover {
    text-decoration: none;
}
input:disabled,
textarea:disabled {
    background-color: #e8ebef !important;
    cursor: not-allowed;
}
/* .btn:disabled,
.btn:disabled:hover {
	cursor: not-allowed;
	background-color: #007e37;
	color: white;
	opacity: 0.5;
	border: 0px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
} */
